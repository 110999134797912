import { useRef } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';

interface IDividerProps {
    height:number;
    marginLeft?:number;
    marginRight?:number;
}

const DividerWrapper = styled.div<IDividerProps>`
    display: inline-block;
    height: ${props=> props.height}px;
    width: 1px;
    color: black;
    background-color: black;
    display: inline-block;
    /* margin: 0 5px 0 5px; */
    opacity: 0.2;
    margin-left: ${props=> props.marginLeft}px;
    margin-right: ${props=> props.marginRight}px;

`

export const Divider = ({height, marginLeft, marginRight}:IDividerProps) => {

    return <DividerWrapper height={height} marginLeft={marginLeft} marginRight={marginRight}></DividerWrapper>

}

// display: inline-block;
// height: 22px;
// width: 1px;
// color: black;
// /* z-index: 5001; */
// /* position: absolute; */
// background-color: black;
// display: inline-block;
// /* justify-content: revert; */
// /* align-content: baseline; */
// /* align-self: normal; */
// /* padding: 0 5px 0 5px; */
// margin: 0 5px 0 5px;
// opacity: 0.2;