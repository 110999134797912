import axios from "axios";
import { AddToIgnoreListModel } from "../Models/AddToIgnoreListModel";
import { SuggestionModel } from "../Models/SuggestionModel";
import { AddShoppingTemplateModel } from "../Models/AddShoppingTemplateModel";
import { ApiResponseModel } from "../Models/ApiResponseModel";
import { CreateWeekplanDto } from "../Models/CreateWeekplanDto";
import { EditWeekplanDto } from "../Models/EditWeekplanDto";
import { LoginModel } from "../Models/LoginModel";
import { WeekplanModel } from "../Models/WeekplanModel";

const getWeekplansById = async (googleId:string , userId:number): Promise<ApiResponseModel<Array<WeekplanModel>>> => {

    return new Promise(async(resolve, reject) => {
        
        let response = new ApiResponseModel<Array<WeekplanModel>>([]);

        try {

            let test = `https://www.api.thomasrasmussen.dk/api/WeekPlans/GetWeekplansByUser/${userId || 0}/${googleId || "0"}`
            
            let result = await axios.get(`https://www.api.thomasrasmussen.dk/api/WeekPlans/GetWeekplansByUser/${userId || 0}/${googleId || "0"}`);

            if (result.status.toString().startsWith("2")){
                response.isSuccessful = true;
                response.data = result.data as Array<WeekplanModel>
                resolve(response);
            }
            else{
                response.data =  result.data as Array<WeekplanModel>
                response.isSuccessful = false;
                response.error = result.statusText;               
                resolve(response);
            }

        } catch (error) {
            console.log(error);
            response.isSuccessful = false;
             //@ts-ignore
            response.error = error ?? "Ukendt fejl opstod";              
            resolve(response);
        }
    });
}

const saveWeekplan = async (title:string, googleId:string | null, shoppingList:string, weekplan:string, startDay:number, userId?:number): Promise<ApiResponseModel<any>> => {

    return new Promise(async(resolve, reject) => {
        
        let response = new ApiResponseModel(null);

        let dto = new CreateWeekplanDto(title, googleId || null, shoppingList, weekplan, startDay, userId);
        
        try {

             let result = await axios.post("https://www.api.thomasrasmussen.dk/api/WeekPlans/SaveWeekplan", dto);
            // let result = await axios.post("http://localhost:58999/api/WeekPlans/SaveWeekplan", dto);

            if (result.status.toString().startsWith("2")){
                response.isSuccessful = true;
                response.data = result.data as any
                resolve(response);
            }
            else{
                response.data =  result.data as any
                response.isSuccessful = false;
                response.error = result.statusText;               
                resolve(response);
            }

        } catch (error) {
            console.log(error);
            response.isSuccessful = false;
             //@ts-ignore
            response.error = error ?? "Ukendt fejl opstod";              
            resolve(response);
        }
    });
}


const editWeekplan = async (id:number, weekplan:string, shoppingList:string): Promise<ApiResponseModel<any>> => {

    return new Promise(async(resolve, reject) => {
        
        let response = new ApiResponseModel(null);

        let dto = new EditWeekplanDto(id, weekplan, shoppingList);
        
        try {

             let result = await axios.post("https://www.api.thomasrasmussen.dk/api/WeekPlans/EditWeekplan", dto);
            
            if (result.status.toString().startsWith("2")){
                response.isSuccessful = true;
                response.data = result.data as any
                resolve(response);
            }
            else{
                response.data =  result.data as any
                response.isSuccessful = false;
                response.error = result.statusText;               
                resolve(response);
            }

        } catch (error) {
            console.log(error);
            response.isSuccessful = false;
             //@ts-ignore
            response.error = error ?? "Ukendt fejl opstod";              
            resolve(response);
        }
    });
}

const deleteWeekplan = async (userId:number, googleId:string, weekplanId:number):Promise<ApiResponseModel<any>> => {

    return new Promise(async(resolve, reject) => {
        
        let response = new ApiResponseModel<any>(null);

        try {

            let result = await axios.get(`https://www.api.thomasrasmussen.dk/api/WeekPlans/DeleteWeekplan/${googleId}/${userId}/${weekplanId}`);
            // let result = await axios.get(`http://localhost:58999/api/WeekPlans/DeleteWeekplan/${googleId}/${userId || 0}/${weekplanId || 0}`);
            
            if (result.status.toString().startsWith("2")){
                response.isSuccessful = true;
                response.data = null;
                resolve(response);
            }
            else{
                response.data = null;
                response.isSuccessful = false;
                response.error = result.statusText;               
                resolve(response);
            }

        } catch (error) {
            console.log(error);
            response.isSuccessful = false;
             //@ts-ignore
            response.error = error ?? "Ukendt fejl opstod";              
            resolve(response);
        }
    });
}

const login = async(email:string, password:string) => {
    return new Promise<ApiResponseModel<any>>(async(resolve, reject) => {
        
        let response = new ApiResponseModel(null);

        let dto = new LoginModel(email, password)
        dto.website = "ugensmad.nu";
        
        try {

             let result = await axios.post("https://www.api.thomasrasmussen.dk/api/Users/Login", dto);
            // let result = await axios.post("http://localhost:58999/api/WeekPlans/SaveWeekplan", dto);

            if (result.status.toString().startsWith("2")){
                response.isSuccessful = true;
                response.data = result.data as any
                resolve(response);
            }
            else{
                response.data =  result.data as any
                response.isSuccessful = false;
                response.error = result.statusText;               
                resolve(response);
            }

        } catch (error) {
            console.log(error);
            response.isSuccessful = false;
             //@ts-ignore
            response.error = error ?? "Ukendt fejl opstod";              
            resolve(response);
        }
    });
};

const getPlans = async(userId:number, googleId:string): Promise<ApiResponseModel<Array<SuggestionModel>>> => {
    
    return new Promise<ApiResponseModel<Array<SuggestionModel>>>(async(resolve, reject) => {
        
        
        let response = new ApiResponseModel<Array<SuggestionModel>>([], true, "");
      
        
        try {

            let result = await axios.get(`https://www.api.thomasrasmussen.dk/api/WeekPlans/GetPlansV2/${googleId}/${userId}`);
            //  let result = await axios.get(`http://localhost:58999/api/WeekPlans/GetPlansV2/${googleId}/${userId}`);
            // let result = await axios.post("http://localhost:58999/api/WeekPlans/SaveWeekplan", dto);

            if (result.status.toString().startsWith("2")){
                
                response.isSuccessful = true;
                response.data = result.data;
                resolve(response)
            }
            else{
                response.data = result.data;
                response.isSuccessful = false;
                response.error = result.statusText;               
                resolve(response);
            }

        } catch (error) {
            console.log(error);
            response.isSuccessful = false;
            //@ts-ignore
            response.error = error ?? "Ukendt fejl opstod";              
            resolve(response);
        }
    });
};

const addShoppingTemplate = async(userId:number, googleId:string, shoppingTemplate:string) => {
    
    return new Promise<ApiResponseModel<any>>(async(resolve, reject) => {
        
        
        let response = new ApiResponseModel([""], true, "");
      
        let model = new AddShoppingTemplateModel(shoppingTemplate, googleId, userId)
        
        try {

              let result = await axios.post(`https://www.api.thomasrasmussen.dk/api/WeekPlans/AddShoppingTemplate`, model);
            //  let result = await axios.post(`http://localhost:58999/api/WeekPlans/AddShoppingTemplate`, model);

            if (result.status.toString().startsWith("2")){
                
                response.isSuccessful = true;
                response.data = result.data;
                resolve(response);
            }
            else{
                response.data = result.data;
                response.isSuccessful = false;
                response.error = result.statusText;               
                resolve(response);
            }

        } catch (error) {
            console.log(error);
            response.isSuccessful = false;
             //@ts-ignore
            response.error = error ?? "Ukendt fejl opstod";              
            resolve(response);
        }
    });
};

const getShoppingTemplate = async(userId:number, googleId:string) => {
    
    return new Promise<ApiResponseModel<any>>(async(resolve, reject) => {
        
        
        let response = new ApiResponseModel([""], true, "");
      
        
        
        try {

              let result = await axios.get(`https://www.api.thomasrasmussen.dk/api/WeekPlans/GetShoppingTemplate/${googleId}/${userId}/`);
            //  let result = await axios.post(`http://localhost:58999/api/WeekPlans/AddShoppingTemplate`, model);

            if (result.status.toString().startsWith("2")){
                
                response.isSuccessful = true;
                response.data = result.data;
                resolve(response);
            }
            else{
                response.data = result.data;
                response.isSuccessful = false;
                response.error = result.statusText;               
                resolve(response);
            }

        } catch (error) {
            console.log(error);
            response.isSuccessful = false;
             //@ts-ignore
            response.error = error ?? "Ukendt fejl opstod";              
            resolve(response);
        }
    });
};

const addToIgnoreList = async(userId:number, googleId:string, course:string) => {
    
    return new Promise<ApiResponseModel<any>>(async(resolve, reject) => {
        
        
        let response = new ApiResponseModel("", true, "");
      
        
        let model = new AddToIgnoreListModel()
        model.UserId = userId
        model.GoogleId = googleId
        model.Course = course
        
        try {

              let result = await axios.post(`https://www.api.thomasrasmussen.dk/api/WeekPlans/AddToIgnoreList/`, model);
            //   let result = await axios.post(`http://localhost:58999/api/WeekPlans/AddToIgnoreList`, model);

            if (result.status.toString().startsWith("2")){
                
                response.isSuccessful = true;
                response.data = result.data;
                resolve(response);
            }
            else{
                response.data = result.data;
                response.isSuccessful = false;
                response.error = result.statusText;               
                resolve(response);
            }

        } catch (error) {
            console.log(error);
            response.isSuccessful = false;
             //@ts-ignore
            response.error = error ?? "Ukendt fejl opstod";              
            resolve(response);
        }
    });
};

export { getWeekplansById, saveWeekplan, editWeekplan, login, deleteWeekplan, getPlans, addShoppingTemplate, getShoppingTemplate, addToIgnoreList };

