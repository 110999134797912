import { useRef } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';

///// INTERFACES
interface IInputComponentProps {
    placeholder?: string
    widthPercentage: number;
    change?: (e: any) => void;
    focus?: (e:any)=> void;
    blur?:(e:any)=> void;
    className?: string;
    icon?: any;
    value?: string;
    topMargin?: number;
    bottomMargin?: number; 
    hidden?:boolean;
}

interface ILabelProps {
    fixedTop: boolean;
}

///// COMPONENTS
const InputWrapper = styled.div<IInputComponentProps>`
    height:20px;
    position: relative;
    transition: 0.3s all;
    width: ${props => props.widthPercentage}%;   
    margin-top: ${props => props.topMargin}px;  
    margin-bottom: ${props => props.bottomMargin}px;  
    display: ${props=> props.hidden ? 'none' : 'block'};

    textarea:focus, input:focus{
        outline: none;
    }

    @media print{
        margin-top: 40px;
        margin-bottom: 0 !important;

        .shopping-item{
            margin-top: 10px !important;
        }
    }
`
const Input = styled.input`
    padding:6px 12px 6px 0;
    transition: 0.3s all;
    width: 100%;
    border:none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);;
    font-size: 100%;

`

const InputLabel = styled.label<ILabelProps>`
    position: absolute;
    top: ${props => props.fixedTop ? -20 : 5}px;
    pointer-events: none;
    transition: 0.3s all;
    font-size: ${props => props.fixedTop ? 80 : 100}%;
    opacity: 0.8;
`

const IconSection = styled.div`
     position: absolute;
        right: -10px;
        top: 5px;
        cursor: pointer;

        img{
            width: 20px;
        }
`

///// RETURNED 
function InputComponent({ placeholder, widthPercentage, change, className, icon, value, topMargin, bottomMargin, focus, blur, hidden }: IInputComponentProps) {

    const [inputValue, setInputValue] = useState<string>("")
    const [inputFocus, setInputFocus] = useState<boolean>(false)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setInputValue(value || "")
    }, [value])

    const handleInputChange = (e: any) => {
        setInputValue(e.target.value.toString())
        if (change){
            change(inputRef.current?.value)
        }
       
    }

    const handleInputFocus = () => {
        setInputFocus(true)
    }

    const handleInputBlur = () => {
        setInputFocus(false)
    }

    const blockSpecialChar = (e: any)=> {
        
        if (e && e.code === "Comma") {
            e.preventDefault();
        }
    }

    return (
        <InputWrapper widthPercentage={widthPercentage} change={change} topMargin={topMargin} bottomMargin={bottomMargin} focus={focus} blur={blur} hidden={hidden}>
            {placeholder &&
                <InputLabel fixedTop={inputValue.length > 0 || inputFocus}>
                    {placeholder}
                </InputLabel>}
            <Input onBlurCapture={blur} onKeyPress={blockSpecialChar} onFocusCapture={focus} className={className || ''} onBlur={handleInputBlur} onFocus={handleInputFocus} onChange={(e) => { handleInputChange(e) }} ref={inputRef} value={inputValue} ></Input>
            {icon &&
                <IconSection>
                    {icon}
                </IconSection>
            }
        </InputWrapper>
    )
}

export default InputComponent