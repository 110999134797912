import { IconButton } from "@material-ui/core";
import { Button, TextField, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { UserModel } from "../../Models/UserModel";
import { login } from '../../Services/ApiService';
import { ILoginProps } from "../Interfaces/ILoginProps";
import {getGravatarUrl} from "../../Services/Helpers"
import './Login.scss'

export const Login = ({ user, setUser, handleLogout, closeDrawer }: ILoginProps) => {

    const [first, setFirst] = useState(false)

    useEffect(()=> {

        let savedUser = localStorage.getItem("ugensmadUser");
          
            if (savedUser) {

               
                let parsedUser = JSON.parse(savedUser) as UserModel

                if (parsedUser){
                    setUser(parsedUser)
                }

                console.log("Login: ", parsedUser)
            }

    }, [])

    // useEffect(() => {

    //     if (!first && user != null) {

    //         let savedUser = localStorage.getItem("ugensmadUser");
          
    //         if (savedUser) {

               
    //             let parsedUser = JSON.parse(savedUser) as UserModel

    //             if (parsedUser){
    //                 setUser(parsedUser)
    //             }
    //         }

    //         setFirst(true)
    //     }
    // }, [user]);

    

    const setProfilePicture = (email:string):string=> {
        
        if (user?.profilePicture) return user.profilePicture

        let profilePicture = getGravatarUrl(email)
        return profilePicture

    }

    const handleLoginCustomUser = async () => {

        let email = (document.getElementById("customLoginUser") as HTMLInputElement)?.value
        let password = (document.getElementById("customLoginPassword") as HTMLInputElement)?.value

        if (!email || !password) return;

        let res = await login(email, password);
        if (res.isSuccessful) {

            let addedUser = new UserModel(user?.googleId || "", (res.data as any).Id, user?.profilePicture, email)
            setUser(addedUser)
            
            localStorage.setItem("ugensmadUser", JSON.stringify(addedUser))
            closeDrawer()

        }
    }

    return (
        <div>
            {!user?.userId &&
                <div>
                    <TextField variant="outlined" placeholder="Email" fullWidth={true} id="customLoginUser" type="text"></TextField>
                    <div style={{ margin: 10 }}></div>
                    <TextField variant="outlined" placeholder="Password" fullWidth={true} id="customLoginPassword" type="password"></TextField>
                    <div className="userButtonSection">
                        <Button style={{ width: 100 }} variant="contained" color="primary" onClick={handleLoginCustomUser}>Log ind</Button>
                        <span className="createUserSection">eller
                            <a href="https://netradio.nu/#/createuser" target="_blank">opret bruger</a>
                            {/* <Tooltip title="Eksternt link: bruger oprettes via Netradio.nu">
                                <IconButton aria-label="">
                                    <Info />
                                </IconButton>
                            </Tooltip> */}
                        </span>
                        <div style={{marginTop:20}}>
                            <small>Du opretter en bruger via. siden Netradio.nu, da det er samme brugere som bruges. Har du allerede en Netradio.nu bruger, kan du logge ind med den.</small>
                        </div>
                    </div>
                </div>
            }
            {user?.userId != null &&
                <div className="userButtonSection">
                    <Button color="primary" style={{ width: 100 }} variant="contained" onClick={handleLogout}>Log ud</Button>
                </div>
            }
        </div>
    )

};