export class UserModel{
    userId?:number | null;
    googleId:string | null;
    profilePicture?:string | null;
    email?:string | null

    constructor(googleId:string | null, userId?:number | null, profilePicture?:string | null, email?:string | null){
        this.userId = userId;
        this.googleId = googleId;
        this.profilePicture = profilePicture;
        this.email = email;
    }
}