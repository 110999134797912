export class AddShoppingTemplateModel {
    googleId:string | null;
    userId?:number;
    shoppingTemplate:string;

    constructor(shoppingTemplate:string, googleId:string | null, userId?:number){
       
        this.googleId = googleId;
        this.userId = userId;
        this.shoppingTemplate = shoppingTemplate;
    }
}