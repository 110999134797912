import { CircularProgress } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: 1000,
            color: '#fff',
        },
    }),
);

interface ILoaderInterface{
    message:string;
    open:boolean;
}

export const Loader = ({message, open}:ILoaderInterface) => {

    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={message.length > 0}>
           
            <CircularProgress color="inherit" />
            <span style={{marginLeft:20}}>{message}</span>
        </Backdrop>
    )
}
