var gravatar = require('gravatar');

const getStartdayByName = (startDay:string):number=> {
    if (startDay === "Mandag") return 0;
    if (startDay === "Tirsdag") return 1;
    if (startDay === "Onsdag") return 2;
    if (startDay === "Torsdag") return 3;
    if (startDay === "Fredag") return 4;
    if (startDay === "Lørdag") return 5;
    return 6;
};

const getStartdayByIndex = (startDay:number):string=> {
    if (startDay === 0) return "Mandag";
    if (startDay === 1) return "Tirsdag";
    if (startDay === 2) return "Onsdag";
    if (startDay === 3) return "Torsdag";
    if (startDay === 4) return "Fredag";
    if (startDay === 5) return "Lørdag";
    return "Søndag";
};


const generateWeekdays = (startDay: string): Array<string> => {
    let weekdays: any = [];

    if (startDay === "Mandag")
        weekdays = ["Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag", "Søndag"];

    else if (startDay === "Tirsdag")
        weekdays = ["Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag", "Søndag", "Mandag"];

    else if (startDay === "Onsdag")
        weekdays = ["Onsdag", "Torsdag", "Fredag", "Lørdag", "Søndag", "Mandag", "Tirsdag"];

    else if (startDay === "Torsdag")
        weekdays = ["Torsdag", "Fredag", "Lørdag", "Søndag", "Mandag", "Tirsdag", "Onsdag"];

    else if (startDay === "Fredag")
        weekdays = ["Fredag", "Lørdag", "Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag"];

    else if (startDay === "Lørdag")
        weekdays = ["Lørdag", "Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag"];

    else if (startDay === "Søndag")
        weekdays = ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"];

    return weekdays;

}

const clearInputsWithClass = (query:string):void=> {

    let targets =  document.querySelectorAll(query);

    if (!targets || targets.length === 0) return;

    targets.forEach(item=> {

        if (item.tagName === "INPUT"){
            (item as HTMLInputElement).value = "";
        }

    });
}

const getGravatarUrl = (email:string):string => {

    var gravatar = require('gravatar')
    let url = gravatar.url(email, {protocol: 'https'})
    return url
}



export {getStartdayByName, getStartdayByIndex, generateWeekdays, clearInputsWithClass, getGravatarUrl}