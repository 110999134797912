import './App.scss'
import '../src/variables.scss'
import { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
  HashRouter
} from 'react-router-dom'
import Weekplan from './Components/Pages/Weekplan/Weekplan'
import About from './Components/Pages/About/About'
import { UserModel } from './Models/UserModel'
import { WeekplanModel } from './Models/WeekplanModel'
import { getPlans, getWeekplansById } from './Services/ApiService'
import UserContext from './Components/Contexts/UserContext'
import WeekplanContext from './Components/Contexts/WeekplanContext'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Create from './Components/Pages/Create/Create'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Restaurant from '@material-ui/icons/Restaurant';
import Info from '@material-ui/icons/Info';
import { Button, Drawer, Backdrop, Paper, Menu, MenuItem, Dialog, DialogContent, DialogActions, Snackbar, Avatar } from '@material-ui/core'
import { HighlightOff, AccountCircle } from '@material-ui/icons';
import { Login } from './Components/Login/Login'
import { LoginGoogle } from './Components/LoginGoogle/LoginGoogle'
import { Loader } from './Components/Loader/Loader'
import Logo from './Images/groceries.svg';
import React from 'react'
import MenuIcon from '@material-ui/icons/Menu';
import { DialogModel } from './Models/DialogModel'
import { Alert } from '@material-ui/lab'

function App() {
  const [user, setUser] = useState<UserModel | null>(null)
  const [weekplans, setWeekplans] = useState<Array<WeekplanModel> | null>([])
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [loader, setLoader] = useState<string>("")
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dialog, setDialog] = useState<DialogModel | null>(null)
  const [snackSuccess, setSnackSuccess] = useState<string>("")
  const [snackError, setSnackError] = useState<string>("")

  useEffect(() => {
    if (window.location.protocol.indexOf("https") === -1 && window.location.href.indexOf("localhost") === -1) {
      window.location.href = window.location.href.replace("http", "https")
    }

    // showLoader("tester")

  }, [])

  useEffect(() => {
    if (user) {
      getWeekplans()
    

    }
  }, [user]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#393E46'
      },
      secondary: {
        main: '#d8e3ed',
      }
    }
  })

  const getWeekplans = async () => {

    if (user?.googleId || user?.userId) {

      let weekplans = await getWeekplansById(user.googleId || "", user.userId || 0)

      setWeekplans(weekplans.data)
    }
  }

  const handleLogout = () => {
    setUser(null)
    setWeekplans(null)
    localStorage.removeItem("ugensmadUser")
  }

  const showLoader = (message: string): void => {
    setLoader(message)
  }

  const hideLoader = (): void => {
    setLoader("")
  }

  const handleMobileMenuClick = (hash: string) => {
    setAnchorEl(null)
    window.location.hash = `#/${hash}`
  }

  const getSelectedRoute = () => {
    if (window.location.hash.indexOf("Opret") > -1) return "Opret madplan"
    if (window.location.hash.indexOf("Gemte") > -1) return "Gemte madplaner"
    if (window.location.hash.indexOf("Om") > -1) return "Om"
  }

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={user}>
        <WeekplanContext.Provider value={weekplans}>

          <Dialog aria-labelledby="confirm-delete" open={dialog !== null}>
            <DialogContent>
              {dialog?.text}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => {
                dialog?.confirmCallback()
                setDialog(null)
              }}>Ja
              </Button>
              <Button onClick={() => {
                setDialog(null)
              }} color="primary">Nej</Button>
            </DialogActions>
          </Dialog>

          <Snackbar autoHideDuration={5000} open={snackSuccess.length > 0} onClose={()=> { setSnackSuccess("")}}>
            <Alert severity="success">
              {snackSuccess}
            </Alert>
          </Snackbar>

          <Snackbar autoHideDuration={5000} open={snackError.length > 0}>
            <Alert onClose={() => { setSnackError("") }} severity="error">
              {snackError}
            </Alert>
          </Snackbar>

          <Menu
            id="mobileMenu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => { handleMobileMenuClick("Opret") }}>Opret</MenuItem>
            <MenuItem onClick={() => { handleMobileMenuClick("Gemte") }}>Gemte</MenuItem>
            <MenuItem onClick={() => { handleMobileMenuClick("Om") }}>Om</MenuItem>
          </Menu>

          <Loader open={showLoader?.length > 0} message={loader}></Loader>

          <Drawer variant="persistent" anchor="left" open={drawerOpen}>
            <div style={{ padding: 10, maxWidth: 300, minWidth: 300 }}>
              <div onClick={handleDrawerClose} id="closeUserMenu">
                <HighlightOff fontSize="medium" className="drawer-close"></HighlightOff>
              </div>
              <div>
                <AccountCircle></AccountCircle>
                <span style={{ verticalAlign: "super" }}><b>Brugerstyring</b></span>
              </div>
              <div style={{ marginTop: 30, marginBottom: 30 }}>
                <small>Log ind via. en Ugensmad bruger eller Google. Du skal være logget ind for at kunne bruge siden.</small>
              </div>

              <Paper>
                <div style={{ padding: 20 }} >
                  <LoginGoogle closeDrawer={() => { setDrawerOpen(false) }} handleLogout={handleLogout} user={user} setUser={setUser}></LoginGoogle>
                </div>
              </Paper>

              <div style={{ marginTop: 20, marginBottom: 20 }}></div>

              <Paper>
                <div style={{ padding: 20 }}>
                  <Login closeDrawer={() => { setDrawerOpen(false) }} handleLogout={handleLogout} user={user} setUser={setUser}></Login>
                </div>
              </Paper>

            </div>
          </Drawer>

          <HashRouter>

            <Backdrop style={{ zIndex: 1000 }} open={drawerOpen}></Backdrop>

            <div className='app-container'>
              <div className='main-navbar no-print'>
                <div id="logo">
                  <NavLink to='/Opret'>
                    <img width={40} src={Logo}></img>
                    <div>Ugensmad.nu</div>
                  </NavLink>
                </div>
                <div id="navbarMobileMenu">
                  <span>{getSelectedRoute()}</span>
                  <Button aria-controls="menu" aria-haspopup="true" onClick={handleMenuClick}>
                    <MenuIcon>MENU</MenuIcon>
                  </Button>
                </div>
                <div className='navbar-login'>
                  <div onClick={() => { setDrawerOpen(true) }}>
                    <Avatar alt="Profile picture" src={user?.profilePicture || ""} />
                  </div>
                  <Button color="primary" onClick={() => { setDrawerOpen(!drawerOpen) }}></Button>
                </div>
                <NavLink to='/Opret' className='navbar-link'>
                  <AddCircleOutlineIcon></AddCircleOutlineIcon> Opret
                </NavLink>
                <NavLink to='/Madplan' className='navbar-link'>
                  <Restaurant></Restaurant> Gemte
                </NavLink>
                <NavLink to='/Om' className='navbar-link'>
                  <Info></Info> Info
                </NavLink>
              </div>

              {(user?.googleId || user?.userId) && <div>
                <div className='app-content'>
                  <Switch>
                    <Route exact path='/'>
                      <Redirect to='/Opret' />
                    </Route>
                    <Route path='/Madplan'>
                      <Weekplan setSnackbarError={setSnackError} setSnackbarSuccess={setSnackSuccess} setDialog={setDialog} getWeekplans={getWeekplans} showLoader={showLoader} hideLoader={hideLoader}></Weekplan>
                    </Route>
                    <Route path='/Opret'>
                      <Create setSnackbarError={setSnackError} setSnackbarSuccess={setSnackSuccess} setDialog={setDialog} getWeekplans={getWeekplans} showLoader={showLoader} hideLoader={hideLoader}></Create>
                    </Route>
                    <Route path='/Om'>
                      <About userActive={user !== null}></About>
                    </Route>
                    <Route path='*' component={Weekplan} />
                  </Switch>
                </div>
              </div>
              }
              {(!user?.userId && !user?.googleId) &&
                <div id="notLoggedIn">
                  <About userActive={user !== null}></About>
                </div>
              }
            </div>
          </HashRouter>
        </WeekplanContext.Provider>
      </UserContext.Provider>
    </ThemeProvider >
  )
}

export default App
