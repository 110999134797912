export class CreateWeekplanDto{
 
    title:string;
    userId?:number;
    googleId:string | null;
    shoppingList:string;
    weekplan:string;
    startDay:number;

    constructor(title:string, googleId:string | null, shoppingList:string, weekplan:string, startDay:number, userId?:number){
       
        this.title = title;
        this.userId = userId;
        this.googleId = googleId;
        this.shoppingList = shoppingList;
        this.weekplan = weekplan;
        this.startDay = startDay;
    }

    

}