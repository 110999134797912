export class EditWeekplanDto{
 
    id:number;
    weekplan:string;
    shoppingList:string;

    constructor(id:number, weekplan:string, shoppingList:string){
       
        this.id = id;
        this.weekplan = weekplan;
        this.shoppingList = shoppingList;
    }

    

}