import { Paper } from '@material-ui/core'
import './About.scss'
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';

interface AboutInterface {
    userActive: boolean;
}

const About = ({ userActive }: AboutInterface) => {

    return (
        <div className="about-section">
            <Paper>
                {!userActive &&
                    <h3>
                        <PersonAddDisabledIcon> </PersonAddDisabledIcon>Log ind for at bruge Ugensmad
                    </h3>
                }
                <div><b className="about-title">Ugensmad.nu</b> gør det muligt at oprette madplaner og indkøbslister online.</div>
                <div style={{ marginTop: 20 }}>
                    Log ind via. Google eller opret en bruger for at få adgang til at gemme madplaner og indkøbslister online, så du altid har nem og hurtig adgang til dem.
                    Siden er også tilpasset således at listerne nemt kan printes ud og medbringes/hænges op.
                </div>
                <div style={{ marginTop: 20 }}>
                   Hvis du vil læse mere om min baggrund eller andre projekter, kan du besøge <b><a href="https://www.thomasrasmussen.dk" target="_blank">thomasrasmussen.dk</a></b>.
                </div>
                <div style={{ marginTop: 20 }}>
                   Spørgsmål eller kommentarer til siden? Du er velkommen til at kontakte mig på mail: <b>tsn1983@gmail.com</b>
                </div>
            </Paper>
        </div>
    )
}

export default About
