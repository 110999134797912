import './Weekplan.scss'
import { editWeekplan, deleteWeekplan } from '../../../Services/ApiService';
import { useContext, useEffect, useState } from 'react';
import { WeekplanModel } from '../../../Models/WeekplanModel';
import UserContext from '../../Contexts/UserContext';
import WeekplanContext from '../../Contexts/WeekplanContext';
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Paper, Select } from '@material-ui/core';
import { getStartdayByIndex, generateWeekdays } from '../../../Services/Helpers';
import { ISiteProps } from '../../Interfaces/ISiteProps';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Print from '@material-ui/icons/Print';
import InputComponent from '../../Styled/InputComponent/InputComponent';
import { DialogModel } from '../../../Models/DialogModel';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import DateRange from '@material-ui/icons/DateRange';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

const Weekplan = ({ showLoader, getWeekplans, setDialog, setSnackbarSuccess }: ISiteProps) => {

    const user = useContext(UserContext);
    const weekplans = useContext(WeekplanContext);

    const [selectedWeekplan, setSelectedWeekplan] = useState<WeekplanModel | null>(null);
    const [weekdays, setWeekdays] = useState<string[]>([
        "Mandag",
        "Tirsdag",
        "Onsdag",
        "Torsdag",
        "Fredag",
        "Lørdag",
        "Søndag"
    ]);

    const [selectedShoppingList, setSelectedShoppingList] = useState<string[]>([""]);
    const [selectedWeekList, setSelectedWeekList] = useState<string[]>([""]);

    const clearInputs = () => {
        document.querySelectorAll(".weekday-item input").forEach(item => (item as HTMLInputElement).value = "");
    }

    useEffect(() => {

        document.removeEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener("fullscreenchange", handleFullscreenChange);

    }, [])

    const handleFullscreenChange = () => {

        if (document.fullscreenElement) {
            (document.querySelector(".shopping-grid") as HTMLElement).style.overflowY = "auto";
        } else {
            (document.querySelector(".shopping-grid") as HTMLElement).style.overflowY = "initial";
        }

    }

    const handleSaveChanges = async () => {

        showLoader("Gemmer dine ændringer...")

        if (selectedWeekplan && selectedWeekplan.Id) {

            let editedWeekplan = "";
            // for (const [key, value] of Object.entries(selectedWeekList)) {

            //     if (value.length > 0) {
            //         editedWeekplan += value + ",";
            //     }
            // }

            editedWeekplan = [...document.querySelectorAll(".weekday-item")].map(item=> (item as HTMLInputElement).value).toString()

            let editedShoppingList = selectedShoppingList.join(",")

            let res = await editWeekplan(selectedWeekplan.Id, editedWeekplan, editedShoppingList);

            if (res.isSuccessful) {
                getWeekplans()
                setSnackbarSuccess("Madplan rettet med succes")
            }
            showLoader("")
        }
    }

    const handleWeekplanChange = (item: any) => {

        if (item.target.value === "Vælg madplan") return;

        clearInputs();

        let target = weekplans?.find(plan => plan.Title == item.target.value);

        if (target) {
            setSelectedWeekplan(target);

            let startDay = target.Startday ? getStartdayByIndex(target.Startday) : "Mandag";

            setWeekdays(generateWeekdays(startDay));

            let weekplanArr = target.Weekplan?.split(",");
            if (weekplanArr) {
                setSelectedWeekList(weekplanArr);
            }

            let shoppingArr = target.ShoppingList?.split(",");
            if (shoppingArr) {
                setSelectedShoppingList(shoppingArr);
            }

        }
    }

    const deleteWeekplanFunction = async () => {
        let res = await deleteWeekplan(user?.userId || 0, user?.googleId || "", selectedWeekplan?.Id || 0)

        if (res.isSuccessful) {
            getWeekplans()
            setSelectedWeekplan(null)
            setSelectedShoppingList([])

            setSnackbarSuccess("Madplanen slettet med succes")
        }
    }

    const handleDeleteWeekplan = async () => {

        if (selectedWeekplan) {
            setDialog(new DialogModel("Vil du slette madplanen?", deleteWeekplanFunction))
        }
    };

    const deleteShoppingItem = (index: number) => {

        let target = document.querySelector(`.edit-shopping-item-${index}:not(.last-item)`)

        if (target && target.parentElement) {
            let shoppingList = selectedShoppingList.filter(item => (item !== (target as HTMLInputElement).value))
            setSelectedShoppingList(shoppingList)
        }
    }

    const gotoFullscreen = () => {
        document.querySelector(".shopping-grid")?.requestFullscreen()
    }

    return (
        <div>
            <div id="weekplansNavbar" className="no-print">
                <Select placeholder="Vælg madplan" labelWidth={100} variant="standard" color="secondary" value={selectedWeekplan ? selectedWeekplan.Title : "Vælg madplan"} onChange={handleWeekplanChange} title="Vælg madplan" label="Vælg madplan">
                    <MenuItem key="default" value="Vælg madplan" selected={true}>Vælg madplan</MenuItem>
                    {weekplans?.map(item =>
                        <MenuItem key={item.Id?.toString()} value={item.Title}> <small>{item.DateAdded ? new Date(item.DateAdded).toLocaleDateString() + ": " : " "}</small> <b><small>{item.Title}</small></b></MenuItem>
                    )}
                </Select>
                <span id="weekplanButtons" style={{ marginLeft: 10 }}>
                    <Button startIcon={<SaveIcon />} variant="contained" color="primary" disabled={!selectedWeekplan} onClick={handleSaveChanges}>gem</Button>
                    <span style={{ marginLeft: 10 }}></span>
                    <Button startIcon={<Print></Print>} variant="outlined" color="primary" disabled={!selectedWeekplan} onClick={() => { window.print() }}>print</Button>
                    <span style={{ marginLeft: 10 }}></span>
                    <Button startIcon={<DeleteIcon />} variant="outlined" color="primary" disabled={!selectedWeekplan} onClick={() => { handleDeleteWeekplan() }}>slet</Button>
                </span>
            </div>

            <div >
                {selectedWeekplan && <div id="weekplanContainer">
                    <div className="grid-item weekplan-grid">
                        <Paper elevation={2}>
                            <div className="paper-wrapper">
                                <h5 className="no-print create-weekplan-label">
                                    <DateRange></DateRange>
                                    Ugeplan
                                </h5>
                                {weekdays.map((day, index) =>
                                    <InputComponent key={index} topMargin={40} bottomMargin={40} widthPercentage={100} placeholder={day} className={`weekday-item ${day}`} value={selectedWeekList[index]} change={(item) => {
                                        let arr = selectedWeekList
                                        arr[index] = item?.target?.value
                                        setSelectedWeekList({ ...arr })
                                    }}></InputComponent>
                                )}
                            </div>
                        </Paper>
                    </div>

                    <div className="grid-item shopping-grid" >
                        <Paper elevation={2}>
                            <div className="paper-wrapper">
                                <h5 className="no-print create-weekplan-label">
                                    <ShoppingCart></ShoppingCart>
                                    Indkøbsliste
                                </h5>
                                <div className="shoppinglist-container">

                                    <div id="fullscreenButton" onClick={gotoFullscreen}>
                                        <FullscreenIcon></FullscreenIcon>
                                    </div>
                                    {selectedShoppingList.map((item, index) =>
                                        <InputComponent key={index} className={`edit-shopping-item-${index} ${index === selectedShoppingList.length - 1 ? 'last-item' : ''}`} widthPercentage={100} topMargin={40} bottomMargin={40} focus={() => {
                                            if (index + 1 == selectedShoppingList.length) {
                                                setSelectedShoppingList([...selectedShoppingList, ""])
                                            }
                                        }} change={(item) => {
                                            let arr = selectedShoppingList;
                                            arr[index] = item;
                                            setSelectedShoppingList([...arr])

                                        }} value={selectedShoppingList ? selectedShoppingList[index] : ""} icon={
                                            <img className="no-print" onClick={() => { deleteShoppingItem(index) }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAjklEQVRIie2Vyw2AIBQER3uQaIlarhwsRy+Y4AfCPuTmnEx0dwg+FH4MzIAz5FzIZlmAHfCixIXMHjqSDMAaHtyAqaD8nhnVQE4ilysSc3mJpLo8J/ms/CSeEH+7tozzK/GqpZX3FdKuInuh6Ra9vVDLYSwuT92TJSWjaJYocy5LLIdIkjT/XEPjH87PgwNng1K28QMLlAAAAABJRU5ErkJggg==" />
                                        }></InputComponent>
                                    )}
                                </div>
                            </div>
                        </Paper>
                    </div>


                </div>}
            </div>
        </div>
    )
}

export default Weekplan
