import './Create.scss'
import { saveWeekplan, addShoppingTemplate, getShoppingTemplate, getPlans, addToIgnoreList } from '../../../Services/ApiService';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../Contexts/UserContext';
import { Button, Select, MenuItem, Grid, Paper, Drawer, Backdrop } from '@material-ui/core';
import { getStartdayByName, generateWeekdays, getGravatarUrl } from '../../../Services/Helpers';
import { ISiteProps } from '../../Interfaces/ISiteProps';
import Save from '@material-ui/icons/Save';
import Print from '@material-ui/icons/Print';
import DateRange from '@material-ui/icons/DateRange';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Clear from '@material-ui/icons/Clear';
import { HighlightOff } from '@material-ui/icons';
import InputComponent from '../../Styled/InputComponent/InputComponent';
import GetAppIcon from '@material-ui/icons/GetApp';
import { WeekplanModel } from '../../../Models/WeekplanModel';
import { Divider } from '../../Styled/Divider/Divider';
import { DialogModel } from '../../../Models/DialogModel';
import { SuggestionModel } from '../../../Models/SuggestionModel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const Create = ({ getWeekplans, setDialog, setSnackbarError, setSnackbarSuccess }: ISiteProps) => {

    const user = useContext(UserContext);
    const [startDay, setStartDay] = useState<string>("Mandag");
    const [name, setName] = useState<string>(new Date().getDate() + "/" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear())
    const [suggestionOpen, setSuggesionOpen] = useState(false)
    const [selectedDay, setSelectedDay] = useState<string>("")
    const [templateShoppingList, setTemplateShoppingList] = useState<string[]>(["", "", "", "", ""]);
    const [weekdays, setWeekdays] = useState<string[]>([
        "Mandag",
        "Tirsdag",
        "Onsdag",
        "Torsdag",
        "Fredag",
        "Lørdag",
        "Søndag"
    ]);

    const [mondayValue, setMondayValue] = useState<string>("")
    const [tuesdayValue, setTuesdayValue] = useState<string>("")
    const [wednessdayValue, setWednessdayValue] = useState<string>("")
    const [thursdayValue, setThursdayValue] = useState<string>("")
    const [fridayValue, setFridayValue] = useState<string>("")
    const [saturdayValue, setSaturdayValue] = useState<string>("")
    const [sundayValue, setSundayValue] = useState<string>("")

    const [suggestions, setSuggestions] = useState<Array<SuggestionModel>>([]);

    useEffect(() => {
        setStartDay("Mandag");

        window.addEventListener('beforeunload', function (e) {

            if (checkForUnsavedValues()) {
                e.preventDefault();
                e.returnValue = '';
            }
        });

        getSuggestions()

    }, [])

    useEffect(() => {
        setWeekdays(generateWeekdays(startDay));
    }, [startDay]);

    const convertDateToWeeks = (date: string): number => {

        let d = new Date(date)
        let today = new Date()

        let diff = weeksBetween(d, today)

        return diff
    }

    function weeksBetween(d1: any, d2: any): number {
        return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
    }

    const getSuggestions = async () => {
        let res = await getPlans(user?.userId || 0, user?.googleId || "")

        if (res.isSuccessful) {
            setSuggestions(res.data.filter(item => item.Course.length > 0))
        }
    }

    const checkForUnsavedValues = () => {
        let shoppingItems = [...document.querySelectorAll(".shopping-item")].some(item => (item as HTMLInputElement).value?.length > 0)
        let weekdayItems = [...document.querySelectorAll(".weekday-item")].some(item => (item as HTMLInputElement).value?.length > 0)
        return shoppingItems || weekdayItems
    }

    const handleStartDayChange = (val: any) => {
        setStartDay(val.target.value);
    };

    const handleShoppingListFocus = (lastItem: boolean) => {
        if (lastItem) {
            setTemplateShoppingList([...templateShoppingList, ""])
        }
    }

    const handleSaveWeekplan = async () => {

        if (user?.googleId || user?.userId) {

            if (name.length === 0) {
                setSnackbarError("Du skal indtaste et navn til madplanen")
                return;
            }

            let res = await saveWeekplan(name || new Date().toLocaleDateString(), user?.googleId || null, createShoppingList(), createWeekplan(), getStartdayByName(startDay), user?.userId || undefined);

            if (res.isSuccessful) {
                getWeekplans();
                clearWeekplanList()
                clearShoppingList()

                setSnackbarSuccess("Madplanen blev gemt med succes!")
            }
            else {
                setSnackbarError("Det lykkedes ikke at gemme madplanen!")
            }
        }

    }

    const clearWeekplanList = () => {

        try {
            setWeekdays([])

            setTimeout(() => {
                setWeekdays([
                    "Mandag",
                    "Tirsdag",
                    "Onsdag",
                    "Torsdag",
                    "Fredag",
                    "Lørdag",
                    "Søndag"
                ])

                setMondayValue("")
                setTuesdayValue("")
                setWednessdayValue("")
                setThursdayValue("")
                setFridayValue("")
                setSaturdayValue("")
                setSundayValue("")

                setStartDay("Mandag")
            }, 10)
        } catch {

        }

    }

    const clearShoppingList = () => {

        try {

            // setTemplateShoppingList(["", "", "", "", ""])
            setTemplateShoppingList([]);
            setTimeout(() => {
                setTemplateShoppingList(["", "", "", "", ""])
            }, 50)

        } catch {

        }

    }

    const createWeekplan = (): string => {
        try {
            let weekdays = [...document.querySelectorAll(".weekday-item")]
                .map((item => (item as HTMLInputElement).value))
                .toString();
            return weekdays;
        }
        catch {
            return ""
        }
    }

    const createShoppingList = (): string => {
        try {
            let shoppingList = [...document.querySelectorAll(".shopping-item")]
                .filter(item => (item as HTMLInputElement).value.length > 0)
                .map((item => (item as HTMLInputElement).value))
                .toString();
            return shoppingList.substr(0, shoppingList.length);
        }
        catch {
            return ""
        }
    }

    const handlePrintClick = () => {
        window.print()
    }

    const showSuggestions = (day: string) => {
        setSuggesionOpen(!suggestionOpen)
        setSelectedDay(day)
    }

    const handleSuggestionChange = (e: any) => {

        if (selectedDay) {

            let target = (document.querySelector(`.day-${selectedDay}`) as HTMLInputElement)
            if (target) {

                if (selectedDay === "Mandag") setMondayValue(e.target.value)
                if (selectedDay === "Tirsdag") setTuesdayValue(e.target.value)
                if (selectedDay === "Onsdag") setWednessdayValue(e.target.value)
                if (selectedDay === "Torsdag") setThursdayValue(e.target.value)
                if (selectedDay === "Fredag") setFridayValue(e.target.value)
                if (selectedDay === "Lørdag") setSaturdayValue(e.target.value)
                if (selectedDay === "Søndag") setSundayValue(e.target.value)
                setSuggesionOpen(false)
            }
        }
    }

    const setWeekplanValue = (day: string) => {
        if (day === "Mandag") return mondayValue.length > 0 ? mondayValue : "";
        if (day === "Tirsdag") return tuesdayValue.length > 0 ? tuesdayValue : "";
        if (day === "Onsdag") return wednessdayValue.length > 0 ? wednessdayValue : "";
        if (day === "Torsdag") return thursdayValue.length > 0 ? thursdayValue : "";
        if (day === "Fredag") return fridayValue.length > 0 ? fridayValue : "";
        if (day === "Lørdag") return saturdayValue.length > 0 ? saturdayValue : "";
        if (day === "Søndag") return sundayValue.length > 0 ? sundayValue : "";
    }

    const handleAddShoppingTemplate = async () => {

        let result = await addShoppingTemplate(user?.userId || 0, user?.googleId || "", createShoppingList())
        if (result.isSuccessful) {

        }
    }

    const handleGetShoppingTemplate = async () => {
        try {


            let result = await getShoppingTemplate(user?.userId || 0, user?.googleId || "")
            if (result.isSuccessful) {

                clearShoppingList()

                setTimeout(() => {

                    let data = result.data as WeekplanModel;

                    if (data.ShoppingList) {

                        setTemplateShoppingList(["", "", "", "",])

                        let items = [...data.ShoppingList?.split(','), '']
                        setTemplateShoppingList(items)
                    }
                }, 100);
            }
        }
        catch (exception) {
            console.log(exception)
        }
    }

    const deleteShoppingItem = (index: number) => {

        try {
            let target = document.querySelector(`.shopping-item-${index}`) as HTMLInputElement
            if (target && target.parentElement) {
                target.parentElement.classList.add("hidden")
            }
        }
        catch {

        }
    }

    return (

        <div>

            <Backdrop style={{ zIndex: 1000 }} open={suggestionOpen}></Backdrop>

            <Drawer className="suggestions-drawer" variant="temporary" anchor="left" open={suggestionOpen}>
                <div onClick={() => { setSuggesionOpen(false) }} id="closeSuggestions">
                    <HighlightOff fontSize="medium" className="drawer-close"></HighlightOff>
                </div>

                <div>
                    <small>Tidligere retter</small>
                    <Select onChange={handleSuggestionChange} fullWidth={true} variant="outlined" title="Vælg ret" label="Vælg ret">
                        {suggestions?.map((item, index) =>
                            <MenuItem key={index} value={item.Course}>
                                <div>
                                    {item.Date &&
                                        <small>ca. {convertDateToWeeks(item.Date.toString())} uger siden</small>
                                    }
                                    <div>{item.Course}</div>
                                    <span className="menuitem-delete" onClick={async (e) => {

                                        e.stopPropagation()

                                        let res = await addToIgnoreList(user?.userId || 0, user?.googleId || "", item.Course)
                                        if (res.isSuccessful) {
                                            let filtered = suggestions.filter(current => current.Course !== item.Course)
                                            setSuggestions(filtered)
                                        }
                                        else {
                                            setSnackbarError("Det lykkedes ikke at slette retten fra listen")
                                        }

                                    }}>
                                        <DeleteForeverIcon></DeleteForeverIcon>
                                    </span>
                                </div>
                            </MenuItem>
                        )}
                    </Select>
                    <div>
                        <small>Valgt ret tilføjes til {selectedDay}</small>
                    </div>
                </div>
            </Drawer>

            <div>

                <Grid container={true} justifyContent="center" className="no-print">
                    <div>
                        <small>Startdag</small>
                        <Select variant="standard" value={startDay} onChange={handleStartDayChange} title="Startdag" fullWidth={true} label="Startdag">
                            <MenuItem value="Mandag">Mandag</MenuItem>
                            <MenuItem value="Tirsdag">Tirsdag</MenuItem>
                            <MenuItem value="Onsdag">Onsdag</MenuItem>
                            <MenuItem value="Torsdag">Torsdag</MenuItem>
                            <MenuItem value="Fredag">Fredag</MenuItem>
                            <MenuItem value="Lørdag">Lørdag</MenuItem>
                            <MenuItem value="Søndag">Søndag</MenuItem>
                        </Select>
                    </div>

                    <div id="weekplanNameSection">
                        <InputComponent value={name} change={(val) => {
                            setName(val)
                        }} placeholder="Navn på madplan" widthPercentage={80}></InputComponent>
                    </div>
                    <div id="weekplanCreateButtons">
                        <Button startIcon={<Save></Save>} variant="contained" color="primary" onClick={() => handleSaveWeekplan()} >gem</Button>
                        <span style={{ marginLeft: 5, marginRight: 5 }}></span>
                        <Button startIcon={<Print></Print>} variant="contained" color="primary" onClick={handlePrintClick}>print</Button>
                    </div>
                </Grid>

                <div style={{ marginTop: 50 }} className="no-print"></div>

                <div id="createContainer">
                    <div>
                        <div className="icon-section no-print">
                            <span onClick={() => { clearWeekplanList() }}>
                                <Clear></Clear>
                            </span>
                        </div>
                        <Paper elevation={2} >
                            <div className="paper-wrapper">
                                <h5 className="no-print create-weekplan-label">
                                    <DateRange></DateRange>
                                    Ugeplan
                                </h5>
                                <div>
                                    {weekdays.map(day =>
                                        <InputComponent key={day} topMargin={40} bottomMargin={40} value={setWeekplanValue(day)} change={() => { }} placeholder={day} className={`weekday-item day-${day}`} widthPercentage={100} icon={
                                            <img className="no-print" onClick={() => { showSuggestions(day) }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAT0lEQVRIiWNgGAUDDRihNBMDA0MUlL2UgYHhP7UtioEa+p+BgSEaSfw/BRjucpoCRiQ6CmrzcgYaBNHQBaOpiCAYTUUEwWgqIghGU9EIAABcu0P/76QfqgAAAABJRU5ErkJggg==" />
                                        }></InputComponent>
                                    )}
                                </div>
                            </div>
                        </Paper>
                    </div>
                    <div>
                        <div className="icon-section no-print">
                            <span onClick={() => { clearShoppingList() }}>
                                <Clear></Clear>
                            </span>
                            <Divider height={22} marginLeft={10} marginRight={10}></Divider>
                            <span onClick={() => {
                                setDialog(new DialogModel("Vil du gemme indkøbslisten som din standardliste?", handleAddShoppingTemplate))
                            }}>
                                <Save></Save>
                            </span>
                            <span onClick={() => { handleGetShoppingTemplate() }}>
                                <GetAppIcon></GetAppIcon>
                            </span>
                        </div>
                        <Paper elevation={2}>
                            <div className="paper-wrapper">
                                <h5 className="no-print create-weekplan-label">
                                    <ShoppingCart></ShoppingCart>
                                    Indkøbsliste
                                </h5>
                                <div className="shoppinglist-container">
                                    {templateShoppingList && templateShoppingList.map((item, index) =>
                                        <InputComponent key={index} value={(templateShoppingList && templateShoppingList[index]) || ''} topMargin={40} bottomMargin={40} widthPercentage={100} focus={() => {
                                            handleShoppingListFocus(index === templateShoppingList?.length - 1)
                                        }} className={index === templateShoppingList?.length - 1 ? `last-item shopping-item shopping-item-${index}` : `shopping-item shopping-item-${index}`} icon={
                                            <img hidden={index === templateShoppingList?.length - 1} className="no-print" onClick={() => { deleteShoppingItem(index) }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAjklEQVRIie2Vyw2AIBQER3uQaIlarhwsRy+Y4AfCPuTmnEx0dwg+FH4MzIAz5FzIZlmAHfCixIXMHjqSDMAaHtyAqaD8nhnVQE4ilysSc3mJpLo8J/ms/CSeEH+7tozzK/GqpZX3FdKuInuh6Ra9vVDLYSwuT92TJSWjaJYocy5LLIdIkjT/XEPjH87PgwNng1K28QMLlAAAAABJRU5ErkJggg==" />
                                        }></InputComponent>
                                    )}
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Create
