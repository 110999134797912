export class ApiResponseModel<T>{
    data: T;
    isSuccessful:boolean | undefined = false;
    error:string | undefined = "";

    constructor(data: T, isSuccessful:boolean = false, error:string = ""){
        this.data = data;
        this.isSuccessful = isSuccessful;
        this.error = error;
    }
}