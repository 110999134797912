import { UserModel } from "../../Models/UserModel";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  GoogleLogout
} from 'react-google-login'
import { ILoginProps } from "../Interfaces/ILoginProps";
import { useEffect } from "react";

export const LoginGoogle = ({ setUser, user, handleLogout, closeDrawer}:ILoginProps) => {

  const handleLogin = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {

    let profileImage = (response as GoogleLoginResponse).profileObj?.imageUrl
    let email = (response as GoogleLoginResponse).profileObj?.email
  
    setUser(new UserModel((response as GoogleLoginResponse).googleId, user?.userId, user?.profilePicture ? user?.profilePicture : profileImage, email))

    closeDrawer()
  }

  useEffect(()=> {
    console.log("Google login: ", user)

  }, [user])
  
  const handleFailure = () => {
  
  }
  // clientId='56366426256-sg130gv50gdqes1v7kcdrga3780ks5jo.apps.googleusercontent.com'
 
  return(
    <div>
      <div>
        {!user?.googleId && (
          <GoogleLogin
            className='google-button'
            clientId='640171494004-9l2c8vn93ir61ht5f7bu1sgjncd9c1e6.apps.googleusercontent.com'
            buttonText='Login'
            onSuccess={handleLogin}
            onFailure={handleFailure}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
          />
        )}
      </div>
      <div>
        {user?.googleId && (
          <GoogleLogout
            className='google-button'
            clientId='640171494004-9l2c8vn93ir61ht5f7bu1sgjncd9c1e6.apps.googleusercontent.com'
            buttonText='Logout'
            onLogoutSuccess={handleLogout}
          ></GoogleLogout>
        )}
      </div>
    </div>
  )

};

